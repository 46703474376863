<template>
  <div class="final">
    <Waiting :value="value" />
  </div>
</template>

<style lang="scss">
.final {
  padding: 20px;
  text-align: center;
}
</style>

<script>
import gotoShowcase from "@frontend/fin-recurrent-main/services/goto-showcase.js";
import Waiting from "./waiting/waiting.vue";

export default {
  name: "Final",
  components: {
    Waiting,
  },
  data() {
    return {
      value: 0,
      curTimer: null,
      tikTime: 1,
    };
  },
  mounted() {
    this.tikTime = 50;
    this.curTimer = setTimeout(this.tiktak.bind(this), this.tikTime);
  },
  methods: {
    tiktak() {
      if (!this.curTimer) {
        return;
      }

      if (this.value < 99) {
        this.value = this.value + 1;
        this.curTimer = setTimeout(
          this.tiktak.bind(this),
          this.tikTime
        );
        return;
      }

      this.value = 100;
      clearTimeout(this.curTimer);
      this.curTimer = null;
      setTimeout(() => {
        gotoShowcase("/showcase-blue");
      }, 500);
    },
  },
};
</script>
